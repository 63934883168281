<template>
  <div class="mt-6 mb-10">
    <div
      class="border border-blue-200 grid grid-cols-1 rounded xl xl:grid-cols-2"
      style="background-color: #f3f6ff"
    >
      <div class="col-span-1 py-4 pl-4">
        <div
          class="
            flex font-medium items-center text-10 text-gray-600 md:text-sm
          "
        >
          <div class="inline-flex mr-1 text-xl tooltip cursor-pointer">
            <ion-icon name="alert-circle-outline"></ion-icon>
            <span class="tooltiptext w-56 md:w-64">
              Repayment date is the date that you pay back what you’ve
              spent.
            </span>
          </div>

          Next repayment due on
          <span class="font-semibold ml-1 text-blue-600">
            {{ nextCreditCardRepayment }}
          </span>
        </div>
      </div>
      <div class="col-span-1 py-4 pl-4 border-t xl:border-t-0 xl:border-l border-blue-200">
        <div
          class="
            flex font-medium items-center text-10 text-gray-600 md:text-sm
          "
        >
          <div class="inline-flex mr-1 text-xl tooltip cursor-pointer">
            <ion-icon name="alert-circle-outline"></ion-icon>
            <span class="tooltiptext w-56 md:w-64">
              Grace period is the period after your repayment date to pay back
              amount spent or you will be charged an additional daily interest
              of 0.5%.
            </span>
          </div>
          End of grace period
          <span class="font-semibold ml-1 text-blue-600">
            {{ creditCardGracePeriod }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    creditCardGracePeriod: {
      type: String,
      default: "",
    },
    nextCreditCardRepayment: {
      type: String,
      default: "",
    },
  }
};
</script>
